import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'beverages',
  formId: 'Drinks%20(general)',
  title: 'Drikkevarer',
  subTitle: '8 om dagen!',
  icon: '/icons/drinks.svg',
  image: '/assets/images/sunrise/drinks_supermercat.png',
  theme: 'sunrise-drinks',
  sections: [
    {
      sectionId: 'hero',
      label: 'Drikkevarer',
      title: '8 om dagen!',
      description:
        'Vi ved alle, at det koster koncentration og arbejdsglæde at være dehydreret, men hvor mange af os får drukket de der 8 store glas vand om dagen? Vores kunder har lært, at med et lækkert udvalg af drikkevarer går det helt af sig selv!',
      image: '/assets/images/sunrise/drinks_supermercat.png',
      icon: '/icons/drinks.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Skål i juice, vand, energi, rose...!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/drinks/service_1.svg',
          title: 'Sodavand og energidrik',
          description: '',
        },
        {
          icon: '/assets/images/services/drinks/service_2.svg',
          title: 'Lækker juice',
          description: '',
        },
        {
          icon: '/assets/images/services/drinks/service_3.svg',
          title: 'Kolde øl',
          description: '',
        },
        {
          icon: '/assets/images/services/drinks/service_4.svg',
          title: 'Vin og spiritus',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Sådan får du drikkevarer',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/drinks/journey_1.svg',
          title: 'Fortæl os hvad du kan lide',
          description: 'Vi kan levere det hele, så fortæl os, hvad der passer til netop jer.',
        },
        {
          icon: '/assets/images/services/drinks/journey_2.svg',
          title: 'Få et tilbud',
          description: 'Du får et detaljeret, men overskueligt, tilbud. Ingen overraskelser.',
        },
        {
          icon: '/assets/images/services/drinks/journey_3.svg',
          title: 'Ja tak!',
          description: 'Det var det! Nu har du sørget for, at dit hårdtarbejdende team bliver vandet. ',
        },
        {
          icon: '/assets/images/services/drinks/journey_4.svg',
          title: 'Skååål!',
          description: 'Så er det tid til at hæve glassene!',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor vore kunder elsker drikkevarer fra Good Monday',
      description: '',
      image: '/assets/images/services/drinks/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-layers-outline',
          title: 'Fyld op!',
          description:
            'Du kan tilmelde dine drikkevarer vores ‘fyld op’ service, så der altid er noget iskoldt til at slukke tørsten.',
        },
        {
          icon: 'eva eva-people-outline',
          title: 'Kæmpe netværk af kendere',
          description: 'Vi guider dig til de drikkevarer, der passer bedst til jer.',
        },
        {
          icon: 'eva eva-book-outline',
          title: 'Til enhver pengepung',
          description: 'Vi har noget til alle lejligheder og budgetter.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du, at du med Good Mondays ‘fyld op’service aldrig løber tør for drikkevarer igen?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
